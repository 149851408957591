<template>
  <page-container is-show-breadcrumbs style="padding-bottom: 24px" class="custom-el-style">
    <el-button type="primary" @click="addAsset">添加标识</el-button>
    <el-table
      :header-cell-style="{
        height: '60px',
        fontSize: '14px',
        fontWeight: 400,
        color: '#909399',
        background: '#FAFAFA',
      }"
      :data="listData"
      style="margin-top: 16px; font-size: 14px"
    >
      <el-table-column prop="identity_cat_name" label="标识分类" min-width="80" />
      <el-table-column prop="identity_name" label="标识名称" min-width="200" />
      <el-table-column prop="identity_handle" label="标识码" min-width="150">
        <template v-slot="{ row }">
          <a target="_blank" :href="row.gwtUrl" style="color: #4f7afd; text-decoration: none">{{
            row.identity_handle
          }}</a>
        </template>
      </el-table-column>
      <el-table-column label="标识二维码" min-width="80" align="center">
        <template #header>
          标识二维码
          <el-tooltip
            class="item"
            effect="dark"
            content="信息完成工网通入网及区块链上链预计需要1小时左右"
            placement="top-start"
          >
            <i style="margin-left: 5px" class="iconfont icon-shuoming" />
          </el-tooltip>
        </template>
        <template v-slot="{ row }">
          <span
            class="iconfont icon-ico"
            v-if="row.identity_enter_status == 1 || row.isUpChain"
            @click="preview(row)"
          />
          <span style="color: #636363" v-else-if="row.identity_enter_status == 2">更新中</span>
          <span
            style="color: #ffa200"
            v-else-if="row.identity_enter_status == 0 && row.isUpChain == 0"
            >注册中</span
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="80" align="center">
        <template v-slot="{ row }">
          <el-button v-if="row.identity_type == 3" type="text" @click="handleEdit(row.identity_id)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-row>
      <pagination
        :page-index="filterCondition.pageIndex"
        :page-size="filterCondition.pageSize"
        :total="total"
        @sizechange="handleSizeChange"
        @currentchange="handleCurrentChange"
        style="margin-top: 24px"
      />
    </el-row>

    <!-- 预览下载工网通、上链二维码 -->
    <qr-code-dialog
      @close="dialogClose"
      :identity-id="identityId"
      :identity-type="identityType"
      :value="dialogVisible"
      :is-up-chain="isUpChain"
      :gwt-qrcode="gwtQrcode"
      :gwt-url="gwtUrl"
    />
  </page-container>
</template>
<script>
import PageContainer from "@/components/common/PageContainer.vue";
import qrCodeDialog from "@/components/common/qrCode.vue";
import { identityApi } from "@/services/api";
export default {
  components: {
    PageContainer,
    qrCodeDialog,
  },
  data() {
    return {
      isLoad: false,
      dialogVisible: false,
      isUpChain: 0, // 该数据是否上链
      identityId: 0, // 标识id
      identityType: 0, // 标识类型
      gwtQrcode: "", // 标识二维码路径
      gwtUrl: "", // 标识二维码数据流
      filterCondition: {
        pageIndex: 1,
        pageSize: 10,
        identity_type: 3,
      },
      total: 0,
      listData: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      let { dataList, pageInfo } = await identityApi.list(this.filterCondition);
      this.listData = dataList;
      this.total = pageInfo.total;
    },
    addAsset() {
      this.$router.push("/manage/identification/new");
    },
    handleEdit(id) {
      this.$router.push(`/manage/identification/edit/${id}`);
    },
    preview(row) {
      console.log("row", row);
      this.gwtQrcode = row.identity_code_data;
      this.gwtUrl = row.identity_code;
      this.identityId = row.identity_id;
      this.identityType = row.identity_type;
      this.isUpChain = row.isUpChain;
      this.dialogVisible = true;
    },
    dialogClose() {
      this.dialogVisible = false;
    },
    handleSizeChange(val) {
      this.filterCondition.pageSize = val;
      console.log(this.filterCondition);
      this.getList();
    },
    handleCurrentChange(val) {
      this.filterCondition.pageIndex = val;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.icon-ico {
  font-size: 22px;
  cursor: pointer;
}
</style>
